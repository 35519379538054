<template>
  <sales
    :current-user-context="currentUserContext"
    no-data-icon="th-icon-calendar-month"
    widget-key="sales_range"
  />
</template>

<script>
import Sales from './Sales'

export default {
  name: 'SalesRange',
  components: {
    Sales
  },
  props: {
    currentUserContext: {
      type: Object,
      required: false,
      default: () => ({})
    }
  }
}
</script>

<style scoped></style>
